import React from 'react';
import 'chart.js/auto';
import { useAtom } from '@gothub-team/got-atom';
import {
    SelectedDayAtom,
    SelectedMonthAtom,
    SelectedTenantIdAtom,
    SelectedYearAtom,
} from '../../state/states';
import { EDITOR, MAIN } from '../../misc/consts';
import * as R from 'ramda';
import { useGraph } from '../../misc/got.config';
import { useChartData } from '../../hooks/useChartData';
import YearSelection from '../../landlord/YearSelection';
import { useMeterStartValues } from '../../hooks/useMeterStartValue';
import { MonthlyBarChart, YearlyBarChart } from './BarChart';
import { DayLineChartFree, DayLineChartPremium } from './LineChart';
import { FEATURE_MODE, useFeatureMode } from '../../hooks/useFeatureMode';

const createTenantView = tenantID =>
    tenantID
        ? {
              [tenantID]: {
                  include: {
                      node: true,
                  },
              },
          }
        : {};

export default function TenantEnergyGraph() {
    const selectedTenantId = useAtom(SelectedTenantIdAtom);
    const view = createTenantView(selectedTenantId);

    const { pull } = useGraph(MAIN, EDITOR);

    React.useEffect(() => {
        pull(view);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTenantId]);

    const selectedMonth = useAtom(SelectedMonthAtom);
    const selectedYear = useAtom(SelectedYearAtom);
    const selectedDay = useAtom(SelectedDayAtom);

    const chartData = useChartData(selectedYear, selectedMonth, selectedDay);
    const chartMode = R.pathOr('', ['id'], chartData).split('/')[3];
    const chartIsLoaded = chartData?.values?.length > 0;
    const startValues = useMeterStartValues();

    const isPremium = useFeatureMode() === FEATURE_MODE.FULL;
    const DayLineChart = isPremium ? DayLineChartPremium : DayLineChartFree;

    return (
        <div className="h-[calc(100%-7rem)]">
            <div className="flex h-full flex-col items-center justify-center">
                {chartIsLoaded && chartMode !== 'day' && (
                    <div className="flex max-h-full w-[90%] flex-1 flex-col items-center">
                        {chartMode === 'year' && (
                            <YearlyBarChart chartData={chartData} startValues={startValues} />
                        )}
                        {chartMode === 'month' && (
                            <MonthlyBarChart chartData={chartData} startValues={startValues} />
                        )}
                        <div className="flex w-full items-center justify-center p-2">
                            <YearSelection />
                        </div>
                    </div>
                )}
                {chartIsLoaded && chartMode === 'day' && (
                    <div className="flex max-h-full w-[90%] flex-1 flex-col items-center">
                        <DayLineChart chartData={chartData} startValues={startValues} />
                        <div className="flex w-full items-center justify-center p-2">
                            <YearSelection />
                        </div>
                    </div>
                )}
                {!chartIsLoaded && (
                    <div className="flex max-h-full w-[90%] flex-1 flex-col items-center justify-end">
                        <div className="flex w-full items-center justify-center p-2">
                            <YearSelection />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
