import Icon from '../../../components/Icon';
import * as R from 'ramda';
import { floor } from '../../../misc/util';

export const RappenPropInputField = ({
    label = '',
    type = 'text',
    defaultVal = '',
    propName,
    obj,
    setObj,
    suggestion,
}) => {
    const value = floor(2, R.propOr(defaultVal, propName, obj) * 100);
    return (
        <div className="mb-5">
            <label className="mb-1 block text-xs font-medium text-neutral-400">{label}</label>
            <div className="flex flex-row items-center gap-2">
                <input
                    placeholder={label}
                    value={value}
                    onChange={e => {
                        const value = e.target.value * 0.01;
                        setObj(R.assoc(propName, value, obj));
                    }}
                    type={type}
                    name={label}
                    id={label}
                    className="h-10 w-full rounded-lg border-none px-2 py-1 shadow-[0_0_0_1px_#e5e7eb] outline-none placeholder:text-center focus:shadow-[0_0_0_2px_rgb(234,179,8)]"
                />
                {suggestion && (
                    <>
                        <button
                            className="flex cursor-pointer items-center justify-center border-none bg-transparent"
                            onClick={() => {
                                setObj(R.assoc(propName, suggestion, obj));
                            }}
                        >
                            <Icon name="copy-arrow" className="h-6 w-6"></Icon>
                        </button>
                        <div className="whitespace-nowrap">{`${floor(2, suggestion * 100).toFixed(
                            2,
                        )} Rp/kWh`}</div>
                    </>
                )}
            </div>
        </div>
    );
};

export const PropInputField = ({
    label = '',
    type = 'text',
    defaultVal = '',
    propName,
    obj,
    setObj,
    suggestion,
}) => {
    const value = R.propOr(defaultVal, propName, obj);
    return (
        <div className="mb-5">
            <label className="mb-1 block text-xs font-medium text-neutral-400">{label}</label>
            <div className="flex flex-row items-center gap-2">
                <input
                    placeholder={label}
                    value={value}
                    onChange={e => setObj(R.assoc(propName, e.target.value, obj))}
                    type={type}
                    name={label}
                    id={label}
                    className="h-10 w-full rounded-lg border-none px-2 py-1 shadow-[0_0_0_1px_#e5e7eb] outline-none placeholder:text-center focus:shadow-[0_0_0_2px_rgb(234,179,8)]"
                />
                {suggestion && (
                    <>
                        <button
                            className="flex cursor-pointer items-center justify-center border-none bg-transparent"
                            onClick={() => {
                                setObj(R.assoc(propName, suggestion, obj));
                            }}
                        >
                            <Icon name="copy-arrow" className="h-6 w-6"></Icon>
                        </button>
                        <div className="whitespace-nowrap">{`${suggestion.toFixed(
                            4,
                        )} CHF/kWh`}</div>
                    </>
                )}
            </div>
        </div>
    );
};
