import React from 'react';
import { SelectedInvoiceIdAtom, SelectedTenantIdAtom } from '../state/states';
import { useGraph } from '../misc/got.config';
import { EDITOR, MAIN, METER_INVOICE_EDGE } from '../misc/consts';
import * as R from 'ramda';
import Icon from '../components/Icon';

export default function InvoiceRow({ parentId, canDelete }) {
    const { useNode, useFiles, remove, push } = useGraph(MAIN, EDITOR);
    const invoiceN = useNode(parentId);
    const { invoice, sums, paid } = invoiceN || {};
    const { gross } = sums || {};
    const { invoiceNumber, invoiceDate } = invoice || {};

    const url = useFiles(parentId, R.path(['pdf', 'url']));

    const [deleteInvoice, setDeleteInvoice] = React.useState(false);

    return (
        <div
            onClick={() => {
                SelectedInvoiceIdAtom.set(parentId);
            }}
            className="relative h-40 rounded-md bg-slate-400 fill-slate-400  shadow-md shadow-transparent transition-all duration-200 hover:scale-105 hover:fill-yellow-400 hover:shadow-gray-300"
        >
            <div className="h-auto w-36 cursor-pointer overflow-hidden rounded-md border-[1px] border-solid border-slate-400 bg-white transition-all duration-200">
                <div className="text-md flex items-center justify-start p-2 py-1 font-semibold text-slate-600 ">
                    Nr. {invoiceNumber}
                </div>
                <div className="p-3">
                    <p>{invoiceDate}</p>
                    <p>{gross}</p>
                    <p className={`${paid ? 'font-medium text-green-500' : 'text-neutral-400'}`}>
                        {paid ? 'Bezahlt' : 'Ausstehend'}
                    </p>
                </div>
                {!deleteInvoice ? (
                    <div className="flex justify-around p-3">
                        {canDelete && (
                            <div
                                onClick={() => setDeleteInvoice(true)}
                                className="mt-1 flex w-1/2 items-center justify-around"
                            >
                                <Icon
                                    name="trash-can"
                                    className="h-5 cursor-pointer fill-slate-600 hover:fill-yellow-400"
                                />
                            </div>
                        )}

                        {url && (
                            <a
                                href={url}
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                                className="w-1/2"
                            >
                                <div className="mt-1 flex items-center justify-around">
                                    <Icon
                                        name="pdf"
                                        className="h-5 cursor-pointer fill-slate-600 hover:fill-yellow-400"
                                    />
                                </div>
                            </a>
                        )}
                    </div>
                ) : (
                    <div className="flex justify-around p-3">
                        <div
                            onClick={() => setDeleteInvoice(false)}
                            className="mt-1 flex w-1/2 items-center justify-around "
                        >
                            <Icon
                                name="close"
                                className="h-5 cursor-pointer fill-slate-600 hover:fill-yellow-400"
                            />
                        </div>
                        <div
                            onClick={() => {
                                remove(METER_INVOICE_EDGE)(SelectedTenantIdAtom.get())(invoiceN);
                                push();
                            }}
                            className="mt-1 flex w-1/2 items-center justify-around "
                        >
                            <Icon
                                name="check"
                                className="h-5 cursor-pointer fill-slate-600 hover:fill-yellow-400"
                            />
                        </div>
                    </div>
                )}
            </div>
            <Icon
                name="paperclip"
                className="absolute right-1 top-0 h-4 w-4 -translate-y-1/4 fill-inherit"
            />
        </div>
    );
}
