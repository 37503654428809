export const Input = ({ label, type, value, onChange }) => (
    <div>
        <label htmlFor="name" className="block text-xs font-medium text-neutral-400">
            {label}
        </label>
        <input
            placeholder={label}
            value={value}
            onChange={e => onChange(e.target.value)}
            type={type}
            id={label}
            className="h-10 w-full rounded-lg border-none px-2 shadow-[0_0_0_1px_#e5e7eb] outline-none focus:shadow-[0_0_0_2px_rgb(234,179,8)]"
        />
    </div>
);
