import { useEffect, useState } from 'react';
import { herbenergyApi } from '../misc/herbenergy.api';
import { useMeterStartValues } from './useMeterStartValue';

const toApiDate = date => {
    const str = `${new Date(date).toDateString()} GMT+0000`;
    return new Date(str).toISOString();
};

const parse = value => {
    if (typeof value === 'string') {
        return parseFloat(value);
    }
    return value;
};

export const useInvoiceData = (zevId, meterId) => {
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);

    const [invoiceData, setInvoiceData] = useState({});
    const [invoiceDataLoading, setInvoiceDataLoading] = useState('init');

    useEffect(() => {
        const loadInvoiceData = async () => {
            if (!selectedStartDate || !selectedEndDate) return;

            try {
                setInvoiceDataLoading('pending');
                const newInvoiceData = await herbenergyApi.getInvoiceData(
                    zevId,
                    meterId,
                    toApiDate(selectedStartDate),
                    toApiDate(selectedEndDate),
                );
                setInvoiceData(newInvoiceData);
                setInvoiceDataLoading('loaded');
            } catch (err) {
                setInvoiceData({});
                setInvoiceDataLoading('error');
                console.log(err);
            }
        };

        loadInvoiceData();
    }, [zevId, meterId, selectedStartDate, selectedEndDate]);

    const startValues = useMeterStartValues();
    const totalStart = Math.max(invoiceData?.start?.import || 0, startValues.import || 0);
    const totalEnd = Math.max(invoiceData?.end?.import || 0, startValues.import || 0);
    const totalValue = totalEnd - totalStart;

    const internalStart = invoiceData?.start?.internal || 0;
    const internalEnd = invoiceData?.end?.internal || 0;
    const internalValue = internalEnd - internalStart;
    const externalValue = totalValue - internalValue;

    return {
        selectedStartDate,
        selectedEndDate,
        setSelectedStartDate,
        setSelectedEndDate,
        invoiceData,
        invoiceDataLoading,
        totalStart: parse(totalStart),
        totalEnd: parse(totalEnd),
        totalValue: parse(totalValue),
        internalStart: parse(internalStart),
        internalEnd: parse(internalEnd),
        internalValue: parse(internalValue),
        externalValue: parse(externalValue),
    };
};
