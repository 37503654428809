/* eslint-disable react-hooks/exhaustive-deps */
import { EDITOR, MAIN, METER_INVOICE_EDGE } from '../misc/consts';
import * as R from 'ramda';
import { useGraph } from '../misc/got.config';
import {
    SelectedInvoiceIdAtom,
    SelectedTenantIdAtom,
    landlordInvoicePopup,
    selectedZEVID,
} from '../state/states';
import { useAtom } from '@gothub-team/got-atom';
import { useState } from 'react';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import { herbenergyApi } from '../misc/herbenergy.api';
import { useInvoiceData } from './useInvoiceData';
import { floatpoint } from '../misc/util';

const oneDayMs = 1000 * 60 * 60 * 24;

export const openInNewTab = url => {
    const elementId = `a-${v4()}`;
    const link = document.createElement('a');
    link.id = elementId;
    link.hidden = true;
    link.href = url;
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();
};

const notifySuccess = () => toast.success('Rechnung erstellt');
const notifyError = () => toast.error('Erstellen fehlgeschlagen');

const selectTariff = R.propOr({}, 'tariff');

export const useCreateInvoice = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { useNode, push, add, pull, inheritRights } = useGraph(MAIN, EDITOR);

    const zevId = useAtom(selectedZEVID);
    const meterId = useAtom(SelectedTenantIdAtom);
    const selectedInvoiceId = useAtom(SelectedInvoiceIdAtom);

    const landLord = useNode(zevId);
    const tenant = useNode(meterId);

    const tariff = useNode(zevId, selectTariff);
    const [invoiceNumberIF, setInvoiceNumberIF] = useState('');

    const {
        invoiceData,
        invoiceDataLoading,
        selectedEndDate,
        selectedStartDate,
        setSelectedEndDate,
        setSelectedStartDate,
        totalStart,
        totalEnd,
        internalStart,
        internalEnd,
        internalValue,
        externalValue,
    } = useInvoiceData(zevId, meterId);

    const {
        name,
        adress,
        city,
        pLZ,
        tel,
        bank,
        iBAN,
        bIC,
        fax,
        mail,
        amtsgericht,
        geschäftsführer,
        taxNumber,
        // tariffInfo,
    } = landLord || {};

    const { nameT, adressT, object, rentTimePeriod, cityT, pLZT = '' } = tenant || {};

    const invoiceDate = new Date().toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });

    function getDaysBetweenTimestamps(startDay, endDay) {
        const start = new Date(startDay);
        const end = new Date(endDay);
        const diff = end.getTime() - start.getTime() + oneDayMs;
        const days = diff / oneDayMs;
        return days.toFixed(0);
    }

    const contractDays = getDaysBetweenTimestamps(selectedStartDate, selectedEndDate)
        .toString()
        .replace('.', ',');
    const location = [pLZT, cityT].filter(Boolean).join(' ');

    const internalTariff = tariff.internal || 0;
    const internalAmount = internalValue * internalTariff;

    const externalTariff = tariff.external || 0;
    const externalAmount = externalValue * externalTariff;

    const totalAmount = internalAmount + externalAmount;
    const vat = totalAmount * 0.077;
    const totalAmountWithVat = totalAmount + vat;

    let invoiceError = false;
    if (!externalTariff || !internalTariff) {
        invoiceError = 'Bitte Tarifdaten eingeben';
    } else if (!invoiceNumberIF) {
        invoiceError = 'Bitte Rechnungsnummer eingeben';
    } else if (!selectedStartDate) {
        invoiceError = 'Bitte Startdatum wählen';
    } else if (!selectedEndDate) {
        invoiceError = 'Bitte Enddatum wählen';
    } else if (invoiceDataLoading === 'error') {
        invoiceError = 'Etwas ist schief gelaufen. Bitte versuche es erneut.';
    }

    const externalPosition = {
        name: 'Externes Stromprodukt',
        amount: `${floatpoint(externalValue, 3)} kWh`,
        unitPrice: `${floatpoint(externalTariff * 100, 2)} Rp./kWh`,
        totalPrice: `${floatpoint(externalAmount, 2)} CHF`,
    };
    const internalPosition = {
        name: 'Internes Stromprodukt',
        amount: `${floatpoint(internalValue, 3)} kWh`,
        unitPrice: `${floatpoint(internalTariff * 100, 2)} Rp./kWh`,
        totalPrice: `${floatpoint(internalAmount, 2)} CHF`,
    };

    const onSave = async () => {
        if (isLoading) return;
        else if (!invoiceData?.end?.import && !invoiceData?.start?.import) return;
        try {
            setIsLoading(true);
            const invoiceNode = {
                id: selectedInvoiceId,
                paid: false,
                info: {
                    sender: `${name}, ${adress}, ${pLZ} ${city}`,
                    recipient: `${nameT} \n ${adressT} \n ${pLZT} ${cityT}`,
                    // textTop: 'some text top',
                    // textBottom: 'some text bottom',
                    footer: [
                        `Bankverbindung: ${bank} \nIBAN: ${iBAN} \nBIC:${bIC}`,
                        `Tel.: ${tel} \nFax.: ${fax} \nMail: ${mail}`,
                        `Amtsgericht: ${amtsgericht} \nGeschäftsführer: ${geschäftsführer} \nSteuernummer: ${taxNumber}`,
                    ],
                },
                invoice: {
                    contractTimePeriod: `${selectedStartDate.toLocaleDateString('de-DE', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                    })} - ${selectedEndDate.toLocaleDateString('de-DE', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                    })}`,
                    rentTimePeriod: rentTimePeriod,
                    days: contractDays,
                    invoiceDate: invoiceDate,
                    invoiceNumber: invoiceNumberIF,
                    invoiceLocation: location,
                    objectLocation: adressT,
                    object: object,
                    tenant: nameT,
                    meterTotal: [
                        `${floatpoint(totalStart, 3)} kWh`,
                        `${floatpoint(totalEnd, 3)} kWh`,
                    ],
                    meterSolar: [
                        `${floatpoint(internalStart, 3)} kWh`,
                        `${floatpoint(internalEnd, 3)} kWh`,
                    ],
                },
                positions: [externalPosition, internalPosition],
                sums: {
                    net: `${floatpoint(totalAmount, 2)} CHF`,
                    vat: `${floatpoint(vat, 2)} CHF`,
                    gross: `${floatpoint(totalAmountWithVat, 2)} CHF`,
                },
            };
            add(METER_INVOICE_EDGE)(meterId)(invoiceNode);
            inheritRights(selectedInvoiceId)(meterId); // erst to dann from
            await push();
            const result = await herbenergyApi.uploadPdf({
                ...invoiceNode,
                nodeId: selectedInvoiceId,
            });
            pull({
                [selectedInvoiceId]: {
                    include: {
                        files: true,
                    },
                },
            });
            openInNewTab(result.url);
            landlordInvoicePopup.set(false);
            notifySuccess();
        } catch (error) {
            console.log(error);
            notifyError();
        } finally {
            setIsLoading(false);
        }
    };

    return {
        onSave,
        isLoading,
        handleStartDateChange: setSelectedStartDate,
        handleEndDateChange: setSelectedEndDate,
        selectedStartDate,
        selectedEndDate,
        externalPosition,
        internalPosition,
        invoiceNumberIF,
        setInvoiceNumberIF,
        invoiceError,
        invoiceDataLoading,
        nameT,
    };
};
