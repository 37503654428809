import { useAtom } from '@gothub-team/got-atom';
import React, { useEffect, useRef } from 'react';
import { MouseAtom } from '../hooks/useMouse';

export const ToolTip = ({ tooltipAtom, children }) => {
    const isOpen = useAtom(tooltipAtom);

    if (isOpen == null) return null;

    return <Content>{children}</Content>;
};

const Content = ({ children }) => {
    const ref = useRef();

    useEffect(() => {
        const subscriber = {
            next: ([mouseX, mouseY]) => {
                if (!ref.current) return;
                ref.current.style.top = `${mouseY + 20}px`;
                ref.current.style.left = `${mouseX + 10}px`;
            },
        };

        MouseAtom.subscribe(subscriber);
        return () => MouseAtom.unsubscribe(subscriber);
    }, []);

    return (
        <div ref={ref} className="shadow-effect fixed z-[1000] rounded-sm bg-white p-2 text-black">
            {children}
        </div>
    );
};
