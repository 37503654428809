import React from 'react';
import 'chart.js/auto';
import {
    SelectedDayAtom,
    SelectedMonthAtom,
    SelectedTenantIdAtom,
    SelectedYearAtom,
    selectedZEVID,
} from '../state/states';
import { useChartData } from '../hooks/useChartData';
import { useAtom } from '@gothub-team/got-atom';
import YearSelection from './YearSelection';
import { useMeterStartValues } from '../hooks/useMeterStartValue';
import { YearlyBarChartPlain } from '../tenant/TenantEnergyGraph/BarChart';
import { YearlyBarChartZevPlain } from '../tenant/TenantEnergyGraph/BarChartZev';

export default function TenantBar() {
    const zevId = useAtom(selectedZEVID);
    const tenantId = useAtom(SelectedTenantIdAtom);

    const selectedMonth = useAtom(SelectedMonthAtom);
    const selectedYear = useAtom(SelectedYearAtom);
    const selectedDay = useAtom(SelectedDayAtom);

    const chartData = useChartData(selectedYear, selectedMonth, selectedDay);
    const chartIsLoaded = chartData?.values?.length > 0;

    const startValues = useMeterStartValues();

    return (
        <div className="flex h-[calc(100%-7rem)] w-full items-center">
            {chartIsLoaded && zevId !== tenantId && (
                <div className="m-5 max-h-full w-full">
                    <YearlyBarChartPlain chartData={chartData} startValues={startValues} />
                    <div className="flex w-full items-center justify-center p-2">
                        <YearSelection />
                    </div>
                </div>
            )}
            {chartIsLoaded && zevId === tenantId && (
                <div className="m-5 max-h-full w-full">
                    <YearlyBarChartZevPlain chartData={chartData} startValues={startValues} />
                    <div className="flex w-full items-center justify-center p-2">
                        <YearSelection />
                    </div>
                </div>
            )}
            {!chartIsLoaded && (
                <div className="m-5 h-full w-full">
                    <div className="flex h-full w-full items-end justify-center p-2">
                        <YearSelection />
                    </div>
                </div>
            )}
        </div>
    );
}
